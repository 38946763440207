import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import { PostTitle } from '../components/post-details/post-details.style';
import SEO from '../components/seo';
import { PageContentWrapper } from '../templates/templates.style';

export const pageQuery = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const SectionTitle = styled.h2`
  color: #d10068;
  margin-bottom: 10px;
`;

const TermsOfUsePage = (props: any) => {
  const siteUrl = props.data.site.siteMetadata.siteUrl;

  return (
    <Layout isHideInstagram={true}>
      <SEO title="Terms Of Use" />
      <PageContentWrapper>
        <PostTitle>Terms Of Use</PostTitle>
        <div>
          <SectionTitle>Ownership of copyright</SectionTitle>
          <p>
            The Copyright in this website and the material and graphic artworks on this website is
            owned by {siteUrl}. All Pages on this website is original and property of {siteUrl}.
            Except affiliate program's graphic images and links used on our site.
          </p>
        </div>
        <div>
          <SectionTitle>Copyright license</SectionTitle>
          <p>
            {siteUrl} grant you use of this site for only personal and Non-commercial , all other
            rights are reserved.
          </p>
        </div>
        <div>
          <SectionTitle>Permissions</SectionTitle>
          <p>
            You may request permission to use the copyright materials on this website by writing to{' '}
            <Link to="/contact-us">Contact us</Link>.
          </p>
        </div>
        <div>
          <SectionTitle>Infringing material</SectionTitle>
          <p>
            If you become aware of any material on the website that you believe infringes your or
            any other person's copyright, please report this by email to{' '}
            <Link to="/contact-us">Contact us</Link>.
          </p>
        </div>
        <p>Thank you for your visit.</p>
        <p>
          Have any question? Feel Free to <Link to="/contact-us">Contact us</Link>.
        </p>
      </PageContentWrapper>
    </Layout>
  );
};

export default TermsOfUsePage;
